import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 250px;

  div.ui.compact.menu {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

    div.ui.active.visible.pointing.dropdown.link.item.titulo {
      flex: 1;
      width: 100%;
    }
  }



  ${(props) =>
    props.warning &&
    css`
      div.ui.compact.menu {
        background-color: #fff6f6;
        border-color: #e0b4b4;
        div.ui.pointing.dropdown.link.item.titulo > div.divider.text {
          color: #9f3a38 !important;
        }
      }
    `};
`
