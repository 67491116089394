export function getAllTabulationsRequest({ domainId }) {
  return {
    type: '@tabulations/GET_TABULATIONS_REQUEST',
    payload: { domainId },
  }
}

export function getAllTabulationsSuccess(tabulations) {
  return {
    type: '@tabulations/GET_TABULATIONS_SUCCESS',
    payload: { tabulations },
  }
}

export function getAllTabulationsFailure() {
  return {
    type: '@tabulations/GET_TABULATIONS_FAILURE',
  }
}
