import React, { useEffect, useMemo } from 'react'
import { Tab } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  changeTabPosition,
  checkOpenTicketsRequest,
  openTicketsRequest,
} from '~/store/modules/websocket/actions'
import { getAllTabulationsRequest } from '~/store/modules/tabulacoes/actions'
import { Container } from './styles'
import Form from './Form'

export default function TabsSemantic() {
  const { search } = useLocation()

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const dispatch = useDispatch()
  const { chamados, index } = useSelector((state) => state.websocket)
  const profile = useSelector((state) => state.user.profile)

  useEffect(() => {
    if (searchParams.get('idTicket')) {
      return
    }
    dispatch(checkOpenTicketsRequest())
    dispatch(openTicketsRequest())
    dispatch(getAllTabulationsRequest({ domainId: profile.id_dominio }))
  }, [dispatch, profile, searchParams])

  const handleTabChange = (e, { activeIndex }) => {
    dispatch(changeTabPosition(activeIndex))
  }

  return (
    <Container>
      <Tab
        className="tabs"
        menu={{ pointing: true }}
        activeIndex={index}
        onTabChange={handleTabChange}
        panes={chamados.map((chamado) => {
          return {
            menuItem: chamado.id.toString(),
            render: () => {
              return <Form chamado={chamado} />
            },
          }
        })}
      />
    </Container>
  )
}
