import styled from 'styled-components';

export const ListContainer = styled.div`
  margin: 0px 5px;
  height: inherit;
  margin-bottom: 5px;

  .div-tamanho {
    height: inherit;
  }

  .div-carregando {
    height: 200px;
  }

  .div-centralizar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  td {
    padding: 5px !important;
  }
`;

export const ListButtons = styled.div`
  display: flex;
  flex-direction: row;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.meio {
    margin-top: 5px;
  }

  div.inline.fields {
    margin: 0 !important;
  }
`;

export const ButtonSpace = styled.div`
  width: 20px;
  height: 10px;
`;
