import styled from 'styled-components';

export const ListContainer = styled.div`
  margin: 0px 5px;
  height: inherit;
  margin-bottom: 5px;

  .div-tamanho {
    height: inherit;
  }

  .div-carregando {
    height: 200px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .div-carregando {
    height: 55vh;
    width: 80%;
  }

  form {
    width: 80%;
  }
`;
