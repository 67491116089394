import { takeLatest, put, call, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import api from '~/services/api'
import { getAllTabulationsSuccess, getAllTabulationsFailure } from './actions'

export function* getAllTabulations({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/tabulacao/domain/${payload.domainId}`,
    )
    yield put(getAllTabulationsSuccess(data))
  } catch (error) {
    toast.error('Falha ao buscar as tabulações')
    yield put(getAllTabulationsFailure())
  }
}

export default all([
  takeLatest('@tabulations/GET_TABULATIONS_REQUEST', getAllTabulations),
])
