import React, { useState } from 'react';
import { Segment, Button, Form, Input } from 'semantic-ui-react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import history from '~/services/history';

import { ListContainer } from './styles';

export default function Ativo() {
  const { dominio, user_basix } = useSelector(state => state.user.profile);
  const [numero, setNumero] = useState('');

  const handleStarCall = async () => {
    try {
      if (/^55..\d{8,9}$/.test(numero)) {
        setNumero(numero.match(/55(..\d{8,9})/)[1]);

        setTimeout(async () => {
          const { data } = await axios.post(
            'https://api.cloudcom.com.br/basix/makecall',
            {
              user: user_basix,
              domain: dominio,
              prefix: '27136',
              destination: numero.match(/55(..\d{8,9})/)[1],
            }
          );

          toast.success(data.message);
          history.push('/dashboard');
        }, 1);
      } else if (/^..\d{8,9}$/.test(numero)) {
        setTimeout(async () => {
          const { data } = await axios.post(
            'https://api.cloudcom.com.br/basix/makecall',
            {
              user: user_basix,
              domain: dominio,
              prefix: '27136',
              destination: numero,
            }
          );

          toast.success(data.message);
          history.push('/dashboard');
        }, 1);
      } else if (/^\d{8,9}$/.test(numero)) {
        setTimeout(async () => {
          const { data } = await axios.post(
            'https://api.cloudcom.com.br/basix/makecall',
            {
              user: user_basix,
              domain: dominio,
              prefix: '27136',
              destination: numero,
            }
          );

          toast.success(data.message);
          history.push('/dashboard');
        }, 1);
      } else {
        toast.error('Numero em formato invalido');
        return;
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        toast.error(error.response.data.error);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <ListContainer>
      <Segment color="blue" className="div-tamanho">
        <Form>
          <Form.Group inline>
            <Form.Field
              control={Input}
              style={{ width: '250px' }}
              fluid
              label="Numero destino"
              placeholder="Numero destino"
              value={numero}
              onChange={e => setNumero(e.target.value)}
            />
          </Form.Group>
        </Form>

        <Button
          primary
          onClick={() => {
            handleStarCall();
          }}
        >
          Iniciar chamada
        </Button>
      </Segment>
    </ListContainer>
  );
}
