import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Form, Message, Loader, Divider } from 'semantic-ui-react'

import {
  getTicketRequest,
  updateTicketRequest,
} from '~/store/modules/ticket/actions'
import { FormContainer } from './styles'

const options = [
  {
    key: 1,
    value: 1,
    text: 'Aberto',
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: 2,
    value: 2,
    text: 'Fechado',
    label: { color: 'black', empty: true, circular: true },
  },
  {
    key: 3,
    value: 3,
    text: 'Pendente',
    label: { color: 'yellow', empty: true, circular: true },
  },
]

export default function Ticket({ match }) {
  const dispatch = useDispatch()
  const { ticket, loading } = useSelector((state) => state.ticket)
  const { tabulations } = useSelector((state) => state.tabulacoes)
  const { id } = match.params

  const [status, setStatus] = useState()
  const [comentario, setComentario] = useState('')

  const [deDID, setDeDid] = useState('')
  const [deDescricao, setDeDescricao] = useState('')

  const [paraDID, setParaDid] = useState('')
  const [paraDescricao, setParaDescricao] = useState('')

  useEffect(() => {
    setStatus(ticket.aberto)
    setComentario(ticket.comentario)

    if (ticket.de && typeof ticket.de === 'string') {
      setDeDid(ticket.de)
    }
    if (ticket.de && typeof ticket.de === 'object') {
      setDeDid(ticket.de.did)
      setDeDescricao(ticket.de.descricao)
    }

    if (ticket.para && typeof ticket.para === 'string') {
      setParaDid(ticket.para)
    }
    if (ticket.para && typeof ticket.para === 'object') {
      setParaDid(ticket.para.did)
      setParaDescricao(ticket.para.descricao)
    }
  }, [ticket])

  useEffect(() => {
    dispatch(getTicketRequest(id))
  }, [dispatch, id])

  function handleSubmit() {
    const data = {
      id,
      comentario,
      aberto: status,
    }

    dispatch(updateTicketRequest(data))
  }

  function getTabulationName(tabulacoes, id) {
    // eslint-disable-next-line no-unused-vars
    for (const tabulacao of tabulacoes) {
      if (tabulacao.id === id) {
        return tabulacao.nome
      }

      if (tabulacao.tabulations.length > 0) {
        const tabulationName = getTabulationName(tabulacao.tabulations, id)
        if (tabulationName) {
          return tabulationName
        }
      }
    }
    return null
  }

  return (
    <FormContainer>
      {!loading ? (
        <Form onSubmit={handleSubmit}>
          <Message>
            <Form.Input name="id" value={id} label="ID:" readOnly />
            <Form.Input
              name="callid"
              value={ticket.call_id}
              label="CallID:"
              readOnly
            />
            <audio
              controls
              src={`https://api.cloudcom.com.br/basix/recording/callid/${ticket.call_id}`}
              type="audio/mp3"
            />
            <Divider />
            <Form.Group widths="equal">
              <Form.Input name="inicio" value={ticket.inicio} label="Inicio:" />
              <Form.Input
                name="termino"
                value={ticket.termino}
                label="Termino:"
              />
              <Form.Select
                fluid
                name="status"
                label="Status:"
                options={options}
                placeholder="Selecione uma opção"
                value={status}
                onChange={(e, v) => setStatus(v.value)}
              />
            </Form.Group>
            <Divider />
            {typeof ticket.de === 'string' && (
              <Form.Input
                name="de.did"
                placeholder="De"
                label="De:"
                value={deDID}
                readOnly
              />
            )}

            {typeof ticket.de === 'object' && (
              <Form.Group widths="equal">
                <Form.Input
                  name="de.did"
                  placeholder="Numero"
                  label="Numero:"
                  value={deDID}
                  readOnly
                />
                <Form.Input
                  name="de.descricao"
                  placeholder="Nome"
                  label="Nome:"
                  value={deDescricao}
                  readOnly
                />
              </Form.Group>
            )}

            {typeof ticket.para === 'string' && (
              <Form.Input
                name="para"
                placeholder="Para"
                label="Para:"
                value={paraDID}
                readOnly
              />
            )}

            {typeof ticket.para === 'object' && (
              <Form.Group widths="equal">
                <Form.Input
                  name="para.did"
                  placeholder="Numero"
                  label="Numero:"
                  value={paraDID}
                  readOnly
                />
                <Form.Input
                  name="para.descricao"
                  placeholder="Nome"
                  label="Nome:"
                  value={paraDescricao}
                  readOnly
                />
              </Form.Group>
            )}
            <Divider />
            <Form.Input
              name="id"
              value={getTabulationName(tabulations, ticket.fk_id_tabulacao)}
              label="Tabulação:"
              readOnly
            />
            <Divider />
            <Form.TextArea
              name="comentario"
              label="Comentarios da ligação"
              placeholder="detalhes da chamada..."
              value={comentario || ''}
              onChange={(e) => setComentario(e.target.value)}
            />
            <Button primary fluid type="submit">
              Alterar
            </Button>
          </Message>
        </Form>
      ) : (
        <Loader active />
      )}
    </FormContainer>
  )
}

Ticket.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}
