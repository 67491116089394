/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  UserAgent,
  // Inviter,
  // Registerer,
  // SessionState,
  // RegistererState,
  Web,
} from 'sip.js';
// import { toast } from 'react-toastify';
// import history from '~/services/history';

import { toast } from 'react-toastify';
import ringToneMp3 from '../assets/ring.mp3';
import dtmf1 from '../assets/webphone/dtmf_1.wav';
import dtmf2 from '../assets/webphone/dtmf_2.wav';
import dtmf3 from '../assets/webphone/dtmf_3.wav';
import dtmf4 from '../assets/webphone/dtmf_4.wav';
import dtmf5 from '../assets/webphone/dtmf_5.wav';
import dtmf6 from '../assets/webphone/dtmf_6.wav';
import dtmf7 from '../assets/webphone/dtmf_7.wav';
import dtmf8 from '../assets/webphone/dtmf_8.wav';
import dtmf9 from '../assets/webphone/dtmf_9.wav';
import dtmf0 from '../assets/webphone/dtmf_0.wav';
import dtmf_asterisk from '../assets/webphone/dtmf_asterisk.wav';
import dtmf_sustenido from '../assets/webphone/dtmf_hashtag.wav';
import hangingUp from '../assets/webphone/hanging_up.mp3';

const callTypeEnum = {
  OUTBOUND: 1,
  INBOUND: 2,
};

window.Notification.requestPermission(permission => {
  if (permission === 'granted') {
    //
  }
});

const WebphoneContext = createContext({});

export const useAppWebphoneContext = () => {
  return useContext(WebphoneContext);
};

export const AppThemeWebphoneProvider = ({ children }) => {
  const [userConfig, setUserConfig] = useState({});
  const [enable, setEnable] = useState(false);
  const [audio, setAudio] = useState(null);
  const [sessionManager, setSessionManager] = useState(null);
  const [currentCall, setCurrentCall] = useState(null);

  const [calls, setCalls] = useState([]);
  const [inCallID, setinCallID] = useState(null);
  const [addCallToList, setaddCallToList] = useState(null);
  const [removeCallIDList, setRemoveCallIDList] = useState(null);

  const [number, setNumber] = useState('');
  const [statusRegistro, setStatusRegistro] = useState('Offline');
  const [classStatusSoftphone, setClassStatusSoftphone] = useState('red');
  const [inCall, setInCall] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [callIsMuted, setCallIsMuted] = useState(false);
  const [callIsHeld, setCallIsHeld] = useState(false);
  const [callType, setCallType] = useState(callTypeEnum.OUTBOUND);

  // const [userAgent, setUserAgent] = useState(null);
  // const [localRegisterer, setLocalRegisterer] = useState(null);
  // const [currentSession, setCurrentSession] = useState(null);
  const [inicioChamada, setInicioChamada] = useState(new Date());
  const [agora, setAgora] = useState(new Date());
  const [ringingAudio, setRingingAudio] = useState(null);

  const audioRef = useRef();
  const ringerRef = useRef();

  const setupRemoteMedia = session => {
    // audioRef.current = new Audio();

    const remoteStream = new MediaStream();
    session.sessionDescriptionHandler.peerConnection
      .getReceivers()
      .forEach(receiver => {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);
        }
      });
    audioRef.current.srcObject = remoteStream;
    audioRef.current.play();
  };

  const cleanupMedia = () => {
    audioRef.current.srcObject = null;
    audioRef.current.pause();
  };

  const setConfiguration = useCallback(config => {
    setUserConfig(config);
  }, []);

  const isHeld = id => {
    return new Promise((resolve, reject) => {
      try {
        const find = calls.find(item => item.id === id);

        if (find) {
          resolve(sessionManager.isHeld(find.currentCall));
        }

        resolve(null);
      } catch (error) {
        reject(error);
      }
    });
  };

  const hold = id => {
    return new Promise((resolve, reject) => {
      try {
        const find = calls.find(item => item.id === id);

        if (find) {
          sessionManager.hold(find.currentCall);
        }
        setInCall(false);
        setIsRinging(false);
        cleanupMedia();

        setinCallID(null);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const unhold = id => {
    return new Promise((resolve, reject) => {
      try {
        const find = calls.find(item => item.id === id);

        if (find) {
          sessionManager.unhold(find.currentCall);
          setCurrentCall(find.currentCall);
        }
        setInCall(true);
        setIsRinging(false);
        setupRemoteMedia(find.currentCall);

        setinCallID(id);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const isMuted = () => {
    return new Promise((resolve, reject) => {
      try {
        if (inCall) {
          resolve(sessionManager.isMuted(currentCall));
        }

        resolve(null);
      } catch (error) {
        reject(error);
      }
    });
  };

  const mute = () => {
    return new Promise((resolve, reject) => {
      try {
        if (inCall) {
          sessionManager.mute(currentCall);
          setCallIsMuted(true);
        }

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const unmute = () => {
    return new Promise((resolve, reject) => {
      try {
        if (inCall) {
          sessionManager.unmute(currentCall);
          setCallIsMuted(false);
        }

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  // const sendDtmf = useCallback(
  //   numberDtmf => {
  //     if (currentSession && currentSession.state === SessionState.Established) {
  //       const options = {
  //         requestOptions: {
  //           body: {
  //             contentDisposition: 'render',
  //             contentType: 'application/dtmf-relay',
  //             content: `Signal=${numberDtmf}\r\nDuration=1000`,
  //           },
  //         },
  //       };

  //       currentSession.info(options);
  //     }
  //   },
  //   [currentSession]
  // );

  const endCall = useCallback(() => {
    if (inCall) {
      sessionManager.hangup(currentCall);
    }
    if (isRinging) {
      if (callType === callTypeEnum.OUTBOUND) {
        sessionManager.hangup(currentCall);
      }
      if (callType === callTypeEnum.INBOUND) {
        sessionManager.decline(currentCall);
      }
    }
  }, [callType, currentCall, inCall, isRinging, sessionManager]);

  const clickButton = useCallback(
    dtmf => {
      if (isRinging && dtmf !== 'hangingUp') {
        return;
      }

      switch (dtmf) {
        case 1:
          audio.src = dtmf1;
          audio.play();
          if (!inCall) {
            setNumber(`${number}1`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String(dtmf));
          }
          break;
        case 2:
          audio.src = dtmf2;
          audio.play();
          if (!inCall) {
            setNumber(`${number}2`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String(dtmf));
          }
          break;
        case 3:
          audio.src = dtmf3;
          audio.play();
          if (!inCall) {
            setNumber(`${number}3`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String(dtmf));
          }
          break;
        case 4:
          audio.src = dtmf4;
          audio.play();
          if (!inCall) {
            setNumber(`${number}4`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String(dtmf));
          }
          break;
        case 5:
          audio.src = dtmf5;
          audio.play();
          if (!inCall) {
            setNumber(`${number}5`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String(dtmf));
          }
          break;
        case 6:
          audio.src = dtmf6;
          audio.play();
          if (!inCall) {
            setNumber(`${number}6`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String(dtmf));
          }
          break;
        case 7:
          audio.src = dtmf7;
          audio.play();
          if (!inCall) {
            setNumber(`${number}7`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String(dtmf));
          }
          break;
        case 8:
          audio.src = dtmf8;
          audio.play();
          if (!inCall) {
            setNumber(`${number}8`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String(dtmf));
          }
          break;
        case 9:
          audio.src = dtmf9;
          audio.play();
          if (!inCall) {
            setNumber(`${number}9`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String(dtmf));
          }
          break;
        case 0:
          audio.src = dtmf0;
          audio.play();
          if (!inCall) {
            setNumber(`${number}0`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String(dtmf));
          }
          break;
        case '*':
          audio.src = dtmf_asterisk;
          audio.play();
          if (!inCall) {
            setNumber(`${number}*`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String('*'));
          }
          break;
        case '#':
          audio.src = dtmf_sustenido;
          audio.play();
          if (!inCall) {
            setNumber(`${number}#`);
          }
          if (inCall) {
            sessionManager.sendDTMF(currentCall, String('#'));
          }
          break;
        case 'clear':
          if (!inCall) {
            setNumber(`${number.slice(0, -1)}`);
          }
          break;
        case 'hangingUp':
          audio.src = hangingUp;
          audio.play();
          if (isRinging) {
            setIsRinging(false);
          }
          endCall();
          break;
        default:
      }
    },
    [audio, currentCall, endCall, inCall, isRinging, number, sessionManager]
  );

  const inicializar = async () => {
    const transportOptions = {
      server: 'wss://webrtc.cloudcom.com.br:7443',
    };

    const uri = UserAgent.makeURI(
      `sip:${userConfig.username}@${userConfig.domain}`
    );

    // const v2Ua = new UserAgent(userAgentOptions);
    const v2SessionManager = new Web.SessionManager(
      'wss://webrtc.cloudcom.com.br:7443',
      {
        userAgentOptions: {
          authorizationUsername: userConfig.username,
          authorizationPassword: userConfig.password,
          viaHost: userConfig.domain,
          connectionTimeout: 10,
          maxReconnectionAttempts: 240,
          reconnectionTimeout: 15,
          transportOptions,
          uri,
          forceRport: true,
          logConfiguration: false,
          logLevel: 'error',
          sessionDescriptionHandlerFactoryOptions: {
            iceGatheringTimeout: 500,
            rtcpMuxPolicy: 'negotiate',
            peerConnectionConfiguration: {
              iceServers: [
                {
                  urls: 'turn:webrtc.cloudcom.com.br:3478',
                  username: 'eoliveira',
                  credential: 'B@lpha9001',
                },
                {
                  urls: 'stun:webrtc.cloudcom.com.br:3478',
                },
              ],
            },
          },
          // logConfiguration: false,
          // logLevel: 'error',
        },
        sendDTMFUsingSessionDescriptionHandler: true,
        registererOptions: {
          expires: 60,
          registrar: uri,
          refreshFrequency: 99,
        },
        media: {
          constraints: { audio: true, video: false },
          // remote: {
          remote: { audio: document.getElementById('remoteAudio') },
          // },
        },
        delegate: {
          onCallCreated: event => {
            setaddCallToList({
              id: event.id,
              number: event.remoteIdentity.uri.user,
              currentCall: event,
              state: 'ringing',
              type: 'outbound',
            });

            setInicioChamada(new Date());
            setCurrentCall(event);
            setInCall(false);
            setIsRinging(true);
            setCallIsHeld(false);
            setCallIsMuted(false);
            setCallType(callTypeEnum.OUTBOUND);
          },
          onCallReceived: async event => {
            setaddCallToList({
              id: event.id,
              number: event.remoteIdentity.uri.user,
              currentCall: event,
              state: 'ringing',
              type: 'inbound',
            });

            setInicioChamada(new Date());
            setCurrentCall(event);
            setInCall(false);
            setIsRinging(true);
            setCallIsHeld(false);
            setCallIsMuted(false);
            setCallType(callTypeEnum.INBOUND);

            setNumber(`${event.remoteIdentity.uri.user}`);
          },
          onCallAnswered: async event => {
            setinCallID(event.id);

            // setupRemoteMedia(event);
            setInicioChamada(new Date());
            setCurrentCall(event);
            setTimeout(() => {
              setInCall(true);
              setIsRinging(false);
              setCallIsHeld(false);
              setCallIsMuted(false);
            }, 1);
          },
          onCallHangup: event => {
            setRemoveCallIDList(event.id);

            // cleanupMedia();
            setInicioChamada(new Date());
            setCurrentCall(event);
            setInCall(false);
            setIsRinging(false);
            setCallIsHeld(false);
            setCallIsMuted(false);
            setNumber('');
          },
          onRegistered: () => {
            setStatusRegistro('Online');
          },
          onUnregistered: () => {
            setStatusRegistro('Offline');
          },
          onCallHold: () => {
            // console.log('onCallHold');
            // console.log({ event });
            // setTimeout(() => {
            //   cleanupMedia();
            //   setTimeout(() => {
            //     setupRemoteMedia(event);
            //   }, 1);
            // }, 1);
          },
        },
      }
    );

    await v2SessionManager.connect();
    await v2SessionManager.register();

    setSessionManager(v2SessionManager);
  };

  const desabilitar = async () => {
    sessionManager.unregister();
    sessionManager.disconnect();
  };

  const handleStartCall = async () => {
    if (isRinging) {
      currentCall.accept();
    } else if (!number) {
      toast.error('destino inválido');
    } else if (number) {
      await sessionManager.call(`sip:${number}@${userConfig.domain}`);
    }
  };

  useEffect(() => {
    if (enable) {
      inicializar();
    }
    if (!enable && sessionManager) {
      desabilitar();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enable]);

  useEffect(() => {
    if (statusRegistro === 'Offline') {
      setClassStatusSoftphone('red');
    } else if (statusRegistro === 'Online') {
      setClassStatusSoftphone('green');
    } else {
      setClassStatusSoftphone('yellow');
    }
  }, [statusRegistro]);

  useState(() => {
    const updateTempo = setInterval(() => {
      setAgora(new Date());
    }, 1000);
    return () => {
      clearInterval(updateTempo);
    };
  }, []);

  useEffect(() => {
    if (isRinging && ringingAudio === null) {
      ringerRef.current.muted = false;
      ringerRef.current.play();

      const currentInterval = setInterval(() => {
        ringerRef.current.muted = false;
        ringerRef.current.play();
      }, 5000);

      setRingingAudio(currentInterval);
    }
    if (isRinging === false && ringingAudio) {
      clearInterval(ringingAudio);
      setRingingAudio(null);
    }
  }, [isRinging, ringingAudio]);

  // const switchCall = async id => {
  // await hold();
  // setTimeout(async () => {
  //   setCalls([
  //     ...calls,
  //     {
  //       id: currentCall.id,
  //       number: currentCall.remoteIdentity.uri.user,
  //       currentCall,
  //       state: 'hold',
  //     },
  //   ]);
  //   setTimeout(async () => {
  //     const callIndex = calls.findIndex(item => item.id === id);
  //     setCurrentCall(calls[callIndex]);
  //     setCalls(calls.filter(item => item.id !== id));
  //     setTimeout(async () => {
  //       await unhold();
  //     }, 1);
  //   }, 1);
  // }, 1);
  // };

  // const transferBlind = useCallback(
  //   ({ to }) => {
  //     const target = UserAgent.makeUri(`sip:${to}@${userConfig.domain}`);
  //     currentSession.refer(target);
  //   },
  //   [userConfig.domain]
  // );

  // const transferAttended = useCallback(
  //   ({ to }) => {
  //     const replacementSession = Inviter(
  //       ua,
  //       UserAgent.makeURI(`sip:${to}@${userConfig.domain}`)
  //     );
  //     currentSession.refer(replacementSession);
  //   },
  //   [userConfig.domain]
  // );

  useEffect(() => {
    if (inCallID) {
      const find = calls.find(item => item.id === inCallID);

      if (find) {
        const callsUpdated = calls.map(item => {
          if (item.id === inCallID) {
            item.state = 'oncall';
          } else {
            item.state = 'hold';
          }

          return item;
        });
        setCalls(callsUpdated);
      }
    } else {
      const callsUpdated = calls.map(item => {
        item.state = 'hold';

        return item;
      });
      setCalls(callsUpdated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inCallID]);

  useEffect(() => {
    if (addCallToList) {
      if (!calls.find(item => item.id === addCallToList.id)) {
        setCalls([...calls, addCallToList]);
      }
    }
    setaddCallToList(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCallToList]);

  useEffect(() => {
    setCalls(calls.filter(item => item.id !== removeCallIDList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeCallIDList]);

  useEffect(() => {
    setAudio(new Audio());
  }, []);

  return (
    <WebphoneContext.Provider
      value={{
        setConfiguration,
        enable,
        calls,
        currentCall,
        setCurrentCall,
        // switchCall,
        // startWebphone,
        // stopWebphone,
        clickButton,
        inCall,
        setInCall,
        isRinging,
        setIsRinging,
        callTypeEnum,
        callType,
        number,
        setNumber,
        handleStartCall,
        statusRegistro,
        classStatusSoftphone,
        inicioChamada,
        agora,
        setEnable,

        isHeld,
        hold,
        unhold,

        isMuted,
        mute,
        unmute,

        callIsMuted,
        callIsHeld,
        sessionManager,
        // transferBlind,
        // transferAttended,
        // sessions,
        // currentSession,
        // setCurrentSession,
        // userAgent,
      }}
    >
      {children}
      <audio
        id="remoteAudio"
        ref={audioRef}
        autoPlay
        controls
        style={{ display: 'none' }}
      />
      <audio
        ref={ringerRef}
        src={ringToneMp3}
        muted
        autoPlay
        controls
        style={{ display: 'none' }}
      />
    </WebphoneContext.Provider>
  );
};

AppThemeWebphoneProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
