import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownMenu, Menu } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import { getAllTabulationsRequest } from '~/store/modules/tabulacoes/actions'
import { updateTabulation } from '~/store/modules/websocket/actions'
import CustomDropdownItem from './dropdownItem'
import { Container } from './style'

export default function Tabulacao({ id_ticket, id_tabulacao }) {
  const dispatch = useDispatch()
  const tabulacoes = useSelector((state) => state.tabulacoes)
  const profile = useSelector((state) => state.user.profile)

  const [tabulacao, setTabulacao] = useState(id_tabulacao)

  function findItemNameById(id, estrutura) {
    for (let i = 0; i < estrutura.length; i++) {
      if (estrutura[i].id === id) {
        return estrutura[i].nome
      }

      if (estrutura[i].tabulations.length > 0) {
        const result = findItemNameById(id, estrutura[i].tabulations)
        if (result) return result
      }
    }
  }

  useEffect(() => {
    setTabulacao(id_tabulacao)
  }, [id_tabulacao])

  useEffect(() => {
    dispatch(getAllTabulationsRequest({ domainId: profile.id_dominio }))
  }, [dispatch, profile])

  const setTabulacaoLocal = (id) => {
    setTabulacao(id)
    dispatch(updateTabulation({ id_ticket, id_tabulacao: id }))
  }

  return (
    <Container warning={tabulacao === 'Selecione uma tabulação'}>
      <Menu compact>
        <Dropdown
          text={
            findItemNameById(tabulacao, tabulacoes.tabulations) ??
            'Selecione uma tabulação'
          }
          pointing
          className="link item titulo"
        >
          <DropdownMenu>
            {tabulacoes.tabulations.map((item) => {
              return (
                <CustomDropdownItem
                  key={item.id}
                  estrutura={item.tabulations}
                  item={item}
                  setTabulacao={setTabulacaoLocal}
                />
              )
            })}
          </DropdownMenu>
        </Dropdown>
      </Menu>
    </Container>
  )
}
