import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Form, Container, Button } from 'semantic-ui-react';

import {
  changeTabPosition,
  changeMomeryDate,
  updateMailingRequest,
  // updateMailingSuccess,
  deleteTicketRequest,
} from '~/store/modules/websocket/actions';
import { updateTicketRequestDashboard } from '~/store/modules/ticket/actions';

import { toast } from 'react-toastify';
import { ListContainer } from './styles';

const optionsChamados = [
  {
    key: 1,
    value: 1,
    text: 'Aberto',
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: 2,
    value: 2,
    text: 'Fechado',
    label: { color: 'black', empty: true, circular: true },
  },
  {
    key: 3,
    value: 3,
    text: 'Pendente',
    label: { color: 'yellow', empty: true, circular: true },
  },
];

const options = [
  { key: 1, text: 'Atendeu e comprou', value: 1 },
  { key: 2, text: 'Atendeu e recusou', value: 2 },
  { key: 3, text: 'Não atendeu', value: 3 },
  { key: 4, text: 'Telefone não existe', value: 4 },
  { key: 5, text: 'Telefone não é do cliente', value: 5 },
  { key: 6, text: 'Recado', value: 6 },
  { key: 7, text: 'Caixa Postal', value: 7 },
  { key: 8, text: 'Chamada Muda', value: 8 },
];

export default function Dashboard() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const { chamados, index } = useSelector(state => state.websocket);
  const [panes, setPanes] = useState([]);

  const [selectCodRetorno, setSelectCodRetorno] = useState();

  useEffect(() => {
    setPanes(
      chamados.map(item => {
        if (item && item.id_pessoa) {
          return {
            menuItem: `${item.id_pessoa}`,
            render: () => (
              <Tab.Pane attached={false}>
                <Container>
                  {/* {JSON.stringify(item)} */}
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        label="Numero da Campanha"
                        placeholder="Numero da Campanha"
                        value={item.numero_campanha}
                      />
                      <Form.Input
                        fluid
                        label="Nome da Campanha"
                        placeholder="Nome da Campanha"
                        value={item.nome_campanha}
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        label="Nome"
                        placeholder="Nome"
                        value={item.nome}
                      />
                      <Form.Input
                        fluid
                        label="Contato 1"
                        placeholder="Contato 1"
                        value={item.phone1}
                      />
                      <Form.Input
                        fluid
                        label="Contato 2"
                        placeholder="Contato 2"
                        value={item.phone2}
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        label="ID Pessoa"
                        placeholder="ID Pessoa"
                        value={item.id_pessoa}
                      />
                      <Form.Input
                        fluid
                        label="CPF"
                        placeholder="CPF"
                        value={item.cpf}
                      />
                      <Form.Input
                        fluid
                        label="Empresa"
                        placeholder="Empresa"
                        value={item.empresa}
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Form.Select
                        fluid
                        label="Código de Retorno"
                        options={options}
                        value={item.codigo_retorno}
                        onChange={(e, v) => {
                          dispatch(
                            changeMomeryDate({
                              codigo_retorno: v.value,
                            })
                          );
                        }}
                        placeholder="Código de Retorno"
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Form.TextArea
                        label="Detalhes da chamada"
                        placeholder={item.comentario || '...'}
                        value={item.comentario}
                        onChange={e => {
                          dispatch(
                            changeMomeryDate({
                              comentario: e.target.value,
                            })
                          );
                        }}
                      />
                    </Form.Group>
                    <Button
                      primary
                      onClick={() => {
                        if (chamados[index].codigo_retorno) {
                          dispatch(
                            updateMailingRequest({
                              ...item,
                              usuario: profile.user_basix,
                            })
                          );
                        } else {
                          toast.error('A tabulação é obrigatória');
                        }
                      }}
                    >
                      Alterar
                    </Button>
                    {/* <Button
                      secondary
                      onClick={() => {
                        dispatch(updateMailingSuccess(item.id));
                      }}
                    >
                      Fechar
                    </Button> */}
                  </Form>
                </Container>
              </Tab.Pane>
            ),
          };
        }
        if (item && item.callid) {
          return {
            menuItem: `${item.id}`,
            render: () => (
              <Tab.Pane attached={false}>
                <Container>
                  {/* {JSON.stringify(item)} */}
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        label="Originador"
                        placeholder="Originador"
                        value={item.from}
                      />
                      <Form.Input
                        fluid
                        label="Destino"
                        placeholder="Destino"
                        value={item.to}
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        label="Usuário"
                        placeholder="Usuário"
                        value={item.user}
                      />
                      <Form.Input
                        fluid
                        label="Domínio"
                        placeholder="Domínio"
                        value={item.domain}
                      />
                      <Form.Input
                        fluid
                        label="CallID"
                        placeholder="CallID"
                        value={item.callid}
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Form.Select
                        fluid
                        label="Código de Retorno"
                        options={optionsChamados}
                        value={item.codigo_retorno}
                        onChange={(e, v) => {
                          dispatch(
                            changeMomeryDate({
                              codigo_retorno: v.value,
                            })
                          );
                        }}
                        placeholder="Código de Retorno"
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Form.TextArea
                        label="Detalhes da chamada"
                        placeholder={item.comentario || '...'}
                        value={item.comentario}
                        onChange={e => {
                          dispatch(
                            changeMomeryDate({
                              comentario: e.target.value,
                            })
                          );
                        }}
                      />
                    </Form.Group>

                    <Button
                      primary
                      onClick={() => {
                        if (item.id && item.comentario && item.codigo_retorno) {
                          dispatch(
                            updateTicketRequestDashboard({
                              id: item.id,
                              comentario: item.comentario,
                              aberto: item.codigo_retorno,
                            })
                          );
                        }
                      }}
                    >
                      Alterar
                    </Button>
                    <Button
                      secondary
                      onClick={() => {
                        dispatch(deleteTicketRequest(item.id));
                      }}
                    >
                      Fechar
                    </Button>
                  </Form>
                </Container>
              </Tab.Pane>
            ),
          };
        }
        return {
          menuItem: ``,
          render: () => <Tab.Pane attached={false}> </Tab.Pane>,
        };
      })
    );
  }, [chamados, dispatch, index, profile.user_basix, selectCodRetorno]);

  const handleTabChange = (e, { activeIndex }) => {
    setSelectCodRetorno(null);
    setTimeout(() => {
      dispatch(changeTabPosition(activeIndex));
    }, 1);
  };

  return (
    <ListContainer>
      <Tab
        menu={{ pointing: true }}
        panes={panes}
        activeIndex={index}
        onTabChange={handleTabChange}
      />
    </ListContainer>
  );
}
