import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  AccordionAccordion,
  Accordion,
  Button,
  Table,
  Icon,
  Checkbox,
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Modal,
  Input,
  Label,
} from 'semantic-ui-react'
import { toast } from 'react-toastify'

import api from '~/services/api'

import { Container, ContainerTab } from './style'

export default function Tabulacoes() {
  const profile = useSelector((state) => state.user.profile)
  const [tabulacoes, setTabulacoes] = useState([])

  const buscarTodasTabulacoes = useCallback(async () => {
    const { data } = await api.get(
      `/tabulacao/all/domain/${profile.id_dominio}`,
    )
    setTabulacoes(data)
  }, [profile.id_dominio])

  const [modalAddOpen, setModalAddOpen] = useState(false)
  const [tabulacaoInput, setTabulacaoInput] = useState('')
  const [idTabRoot, setIdTabRoot] = useState(null)

  const handleChangeActive = async (id) => {
    try {
      await api.post(`/tabulacao/domain/${profile.id_dominio}/tabulacao/${id}`)
      buscarTodasTabulacoes()
      toast.success('Tabulação alterada com sucesso')
    } catch (error) {
      toast.error('Erro ao alterar tabulação')
    }
  }

  useEffect(() => {
    buscarTodasTabulacoes()
  }, [buscarTodasTabulacoes])

  const generatePanels = (tabulacoes) => {
    return tabulacoes.map((tabulacao) => {
      return {
        key: `panel-${tabulacao.id}`,
        title: {
          // tabulacao.nome
          content: (
            <Label
              color={tabulacao.ativo ? 'green' : 'red'}
              content={tabulacao.nome}
            />
          ),
        },
        content: {
          content: (
            <div>
              <ContainerTab>
                <div>
                  <Button
                    icon
                    primary
                    labelPosition="left"
                    size="mini"
                    onClick={() => {
                      setTabulacaoInput('')
                      setIdTabRoot(tabulacao.id)
                      setModalAddOpen(true)
                    }}
                  >
                    <Icon name="sitemap" /> Adicionar Subnível
                  </Button>
                </div>

                <Checkbox
                  toggle
                  label="Habilitado"
                  checked={tabulacao.ativo}
                  onChange={() => {
                    handleChangeActive(tabulacao.id)
                  }}
                />
              </ContainerTab>

              {tabulacao.tabulations.length > 0 && (
                <AccordionAccordion
                  // status={tabulacao.ativo}
                  panels={generatePanels(tabulacao.tabulations)}
                />
              )}
            </div>
          ),
        },
      }
    })
  }

  const handleAddTabulacao = async () => {
    try {
      await api.post(`/tabulacao/domain/${profile.id_dominio}`, {
        nome: tabulacaoInput,
        ativo: true,
        id_pai: idTabRoot,
      })

      buscarTodasTabulacoes()
      toast.success('Tabulação adicionada com sucesso')
    } catch (error) {
      toast.error('Erro ao adicionar tabulação')
    }
  }

  return (
    <Container>
      <Table celled color="blue">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="7">
              <Button
                icon
                labelPosition="left"
                primary
                size="mini"
                onClick={() => {
                  setTabulacaoInput('')
                  setIdTabRoot(null)
                  setModalAddOpen(true)
                }}
              >
                <Icon name="sitemap" /> Adicionar Tabulação
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Accordion panels={generatePanels(tabulacoes)} styled />
        </Table.Body>
      </Table>

      <Modal
        onClose={() => setModalAddOpen(false)}
        onOpen={() => setModalAddOpen(true)}
        open={modalAddOpen}
      >
        <ModalHeader>Adicionar tabulação</ModalHeader>
        <ModalContent>
          <ModalDescription>
            <Input
              placeholder="Tabulação"
              onChange={(e) => setTabulacaoInput(e.target.value)}
              value={tabulacaoInput}
            />
          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <Button color="black" onClick={() => setModalAddOpen(false)}>
            Cancelar
          </Button>
          <Button
            content="Adicionar"
            labelPosition="right"
            icon="checkmark"
            onClick={() => {
              handleAddTabulacao()
              setModalAddOpen(false)
            }}
            positive
          />
        </ModalActions>
      </Modal>
    </Container>
  )
}
