import React from 'react'
import { Dropdown, DropdownMenu, DropdownItem } from 'semantic-ui-react'

export default function CustomDropdownItem({ estrutura, item, setTabulacao }) {
  if (estrutura.length > 0) {
    return (
      <DropdownItem>
        <Dropdown text={item.nome}>
          <DropdownMenu>
            {estrutura.map((subItem) => {
              return (
                <CustomDropdownItem
                  key={subItem.id}
                  estrutura={subItem.tabulations}
                  item={subItem}
                  setTabulacao={setTabulacao}
                />
              )
            })}
          </DropdownMenu>
        </Dropdown>
      </DropdownItem>
    )
  }

  return (
    <>
      <DropdownItem
        className="final_item"
        onClick={() => setTabulacao(item.id)}
      >
        {item.nome}
      </DropdownItem>
    </>
  )
}
