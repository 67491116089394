import produce from 'immer';

const INITIAL_STATE = {
  domains: [],
  users: [],
  user: {},
  loading: false,
  loadingUsers: false,
  loadingUserData: false,
};

export default function ticket(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@allDomains/GET_ALL_DOMAINS_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });
    case '@allDomains/GET_ALL_DOMAINS_SUCCESS':
      return produce(state, draft => {
        draft.domains = action.payload.domains;
        draft.loading = false;
      });
    case '@allDomains/GET_ALL_DOMAINS_FAILURE':
      return produce(state, draft => {
        draft.domains = [];
        draft.loading = false;
      });

    case '@allDomains/GET_ALL_USERS_FROM_DOMAINS_REQUEST':
      return produce(state, draft => {
        draft.loadingUsers = true;
      });
    case '@allDomains/GET_ALL_USERS_FROM_DOMAINS_SUCCESS':
      return produce(state, draft => {
        draft.users = action.payload.users;
        draft.loadingUsers = false;
      });
    case '@allDomains/GET_ALL_USERS_FROM_DOMAINS_FAILURE':
      return produce(state, draft => {
        draft.users = [];
        draft.loadingUsers = false;
      });
    case '@allDomains/CLEAR_ALL_USERS_FROM_DOMAINS_REQUEST':
      return produce(state, draft => {
        draft.users = [];
        draft.loadingUsers = false;
      });

    case '@allDomains/GET_USER_DATA_REQUEST':
      return produce(state, draft => {
        draft.loadingUserData = true;
      });
    case '@allDomains/GET_USER_DATA_SUCCESS':
      return produce(state, draft => {
        draft.user = action.payload.user;
        draft.loadingUserData = false;
      });
    case '@allDomains/GET_USER_DATA_FAILURE':
      return produce(state, draft => {
        draft.user = {};
        draft.loadingUserData = false;
      });

    case '@allDomains/UPDATE_USER_DATA_REQUEST':
      return produce(state, draft => {
        draft.loadingUserData = true;
      });
    case '@allDomains/UPDATE_USER_DATA_SUCCESS':
      return produce(state, draft => {
        draft.loadingUserData = false;
      });
    case '@allDomains/UPDATE_USER_DATA_FAILURE':
      return produce(state, draft => {
        draft.loadingUserData = false;
      });

    default:
      return state;
  }
}
