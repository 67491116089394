import axios from 'axios'

// const api = axios.create({
//   baseURL: 'https://contact.cloudcom.com.br/api/',
// });

const api = axios.create({
  baseURL: 'https://contact-hlm.cloudcom.com.br/api/',
})

// const api = axios.create({
//   baseURL: 'http://localhost:3001',
// })

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { response } = error
    const { status } = response

    if (status === 401) {
      if (response.data.error) {
        if (response.data.error === 'Token invalid') {
          // localStorage.removeItem('persist:basix_contact');
          localStorage.clear()
          window.location.reload()
        }
      }
    }
    return Promise.reject(error)
  },
)

export function clearAll() {
  window.location.reload()
  localStorage.clear()
}

export default api
