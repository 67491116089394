import produce from 'immer'

const INITIAL_STATE = {
  tabulations: [],
  loading: false,
}

export default function ticket(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@tabulations/GET_TABULATIONS_REQUEST':
      return produce(state, (draft) => {
        draft.loading = true
      })
    case '@tabulations/GET_TABULATIONS_SUCCESS':
      return produce(state, (draft) => {
        draft.tabulations = action.payload.tabulations
        draft.loading = false
      })
    case '@tabulations/GET_TABULATIONS_FAILURE':
      return produce(state, (draft) => {
        draft.tabulations = []
        draft.loading = false
      })

    default:
      return state
  }
}
