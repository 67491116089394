import styled from 'styled-components';

export const ListContainer = styled.div`
  margin: 0px 5px;
  height: inherit;
  margin-bottom: 5px;

  > div {
    height: inherit;

    div.ui.segment {
      height: 95.4%;
    }
  }

  .div-tamanho {
    height: inherit;
  }

  .div-carregando {
    height: 200px;
  }
`;
