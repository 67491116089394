import styled from 'styled-components'

export const Container = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  table {
    padding: 5px;
    display: flex;
    flex-direction: column;

    div.accordion.ui.styled {
      width: 100%;
    }
  }
`

export const ContainerTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
