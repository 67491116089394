import { takeLatest, put, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import {
  getAllDomainsSuccess,
  getAllDomainsFailure,
  getAllUserFromDomainsRequest,
  getAllUserFromDomainsSuccess,
  getAllUserFromDomainsFailure,
  getUserDataSuccess,
  getUserDataFailure,
  updateUserDataSuccess,
  updateUserDataFailure,
} from './actions';

export function* getAllDomains() {
  try {
    let { data } = yield call(api.get, `/domains`);
    data = data.sort((a, b) => {
      if (a.dominio < b.dominio) {
        return -1;
      }
      if (a.dominio > b.dominio) {
        return 1;
      }
      return 0;
    });
    yield put(getAllDomainsSuccess(data));
  } catch (error) {
    toast.error('Falha ao buscar os domínios');
    yield put(getAllDomainsFailure());
  }
}

export function* getAllUserFromDomain({ payload }) {
  try {
    const { id } = payload;
    const { data } = yield call(api.get, `/users/${id}`);
    yield put(getAllUserFromDomainsSuccess(data));
  } catch (error) {
    toast.error('Falha ao buscar os usuário do domínio');
    yield put(getAllUserFromDomainsFailure());
  }
}

export function* getUserData({ payload }) {
  try {
    const { userId, domainId } = payload;
    const { data } = yield call(api.get, `/users/${domainId}/${userId}`);
    yield put(getUserDataSuccess(data));
  } catch (error) {
    toast.error('Falha ao buscar dados do usuário');
    yield put(getUserDataFailure());
  }
}

export function* updateUserData({ payload }) {
  try {
    const { data } = payload;
    const { currentDomainId, currentUserId } = data;
    const {
      ativo,
      ativo_dendron,
      ativo_zendesk,
      callCenterGroup,
      dendron_operador,
      dendron_token,
      descricao,
      email,
      email_zendesk,
      gravacao,
      loginlogout,
      mostrarHistorico,
      nome,
      senha,
      sub_dominio_zendesk,
      tipo,
      token_zendesk,
      user_basix,
    } = data;

    let resultRequest = null;

    if (senha) {
      const { data: resultPut } = yield call(
        api.put,
        `/users/${currentDomainId}/${currentUserId}`,
        {
          ativo,
          nome,
          email,
          senha,
          callcenter_group: callCenterGroup,
          user_basix,
          tipo,
          loginlogout,
          gravacao,
          descricao,
          historico: mostrarHistorico,
          ativo_dendron,
          dendron_operador,
          dendron_token,
          ativo_zendesk,
          email_zendesk,
          token_zendesk,
          sub_dominio_zendesk,
        }
      );

      resultRequest = resultPut;
    } else {
      const { data: resultPut } = yield call(
        api.put,
        `/users/${currentDomainId}/${currentUserId}`,
        {
          ativo,
          nome,
          email,
          callcenter_group: callCenterGroup,
          user_basix,
          tipo,
          loginlogout,
          gravacao,
          descricao,
          historico: mostrarHistorico,
          ativo_dendron,
          dendron_operador,
          dendron_token,
          ativo_zendesk,
          email_zendesk,
          token_zendesk,
          sub_dominio_zendesk,
        }
      );

      resultRequest = resultPut;
    }

    yield put(updateUserDataSuccess(resultRequest));
    toast.success('Usuário alterado com sucesso');
    yield put(getAllUserFromDomainsRequest(currentDomainId));
  } catch (error) {
    toast.error('Falha ao atualizar usuário');
    yield put(updateUserDataFailure());
  }
}

export default all([
  takeLatest('@allDomains/GET_ALL_DOMAINS_REQUEST', getAllDomains),
  takeLatest(
    '@allDomains/GET_ALL_USERS_FROM_DOMAINS_REQUEST',
    getAllUserFromDomain
  ),
  takeLatest('@allDomains/GET_USER_DATA_REQUEST', getUserData),
  takeLatest('@allDomains/UPDATE_USER_DATA_REQUEST', updateUserData),
]);
