import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .webphones {
    display: flex;
    height: max-content;
  }

  #webphone {
    display: flex;
    flex-direction: column;
    width: 18.75rem;
    margin: 1.5625rem;
    background: #e4e5e7;
    border-radius: 0.3125rem;
    /* height: 100%; */
    /* background-color: #1189ed; */
  }

  .menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    background-color: #00b37e;
  }

  .menu span {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 9.375rem;
  }

  .menu i {
    font-size: 2.25rem;
    width: 4.68rem;
    height: 4.68rem;
    align-items: center;
    border-radius: 50%;
  }

  #status {
    background: #f1f2f4;
    margin: 5%;
    border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
    padding: 1%;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
  }

  #status.green span {
    color: #00b37e;
  }

  #status.red span {
    color: #d32f2f;
  }

  #status.yellow span {
    color: #ed6c02;
  }

  #status span {
    display: flex;
    align-items: center;
    margin-left: 0.3125rem;
    font-size: 0.9375rem;
  }

  #status.green span::before {
    background-color: #00b37e;
  }

  #status.red span::before {
    background-color: #d32f2f;
  }

  #status.yellow span::before {
    background-color: #ed6c02;
  }

  #status span::before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    display: block;
    border-radius: 50%;
    margin-right: 0.9375rem;
  }

  #display {
    /* width: 80%; */
    background: #f1f2f4;
    margin: 5%;
    border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
    /* height: 15%; */
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.375rem;
    color: #68696b;
    font-weight: bold;
    padding-top: 0.875rem;
    padding-bottom: 0.625rem;
    gap: 0.875rem;
  }

  #display .icon i {
    color: green;
    font-size: 2.25rem;
    /* margin-top: 1.5rem; */
  }

  #display .duration {
    color: #bcbdbf;
  }

  .calls {
    display: flex;
    flex-direction: column;
    border: 2px solid #ed6c02;
    margin: 0.5rem;
    border-radius: 0.6rem;
  }

  .calls.active {
    border: 2px solid #00b37e;
  }

  .calls span {
    margin: 0 auto;
    font-size: 1.2rem;
    margin-top: 1rem;
  }

  .features {
    padding: 0% 0% 5% 0%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    /* display: grid;
    gap: 0rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center; */
  }

  .features.voicemail {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .feature {
    font-size: 1.875rem;
    background-color: #efeff7;
    width: 3.4375rem;
    height: 3.4375rem;
    display: grid;
    justify-items: center;
    align-items: center;
    border-radius: 50%;
    border: 0;
  }

  .feature.muted {
    background-color: #ed6c02;
  }

  .feature.held {
    background-color: #ed6c02;
  }

  .feature:hover {
    background-color: #1189ed;
  }

  .feature:focus {
    /* background-color: #bcbdbf; */
  }

  .keyboard {
    padding: 0% 15%;
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
  }

  .keyboard button {
    width: 3.4375rem;
    height: 3.4375rem;
    background-color: #efeff7;
    border-radius: 50%;
    border: 0;
    text-align: center;
    display: grid;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 1.375rem;
  }

  .keyboard button:hover {
    background-color: #1189ed;
  }

  .keyboard button:focus {
    background-color: #bcbdbf;
  }

  .controls {
    padding: 5% 15%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  .controls.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .controls .control.idle,
  .controls .control.gray,
  .controls .control.incall,
  .controls .control.ringing {
    border: 0;
    background-color: transparent;
  }

  .controls .control i:hover {
    border: 1px solid black;
    border-radius: 50%;
    box-sizing: border-box;
    filter: brightness(120%);
  }

  .controls .control.gray i {
    font-size: 2.25rem;
    /* background-color: #00b37e; */
    width: 3.4375rem;
    height: 3.4375rem;
    display: grid;
    align-items: center;
    border-radius: 50%;
  }

  .controls .control.idle i {
    font-size: 2.25rem;
    background-color: #00b37e;
    width: 3.4375rem;
    height: 3.4375rem;
    display: grid;
    align-items: center;
    border-radius: 50%;
  }

  .controls .control.incall i {
    font-size: 2.25rem;
    background-color: #d32f2f;
    width: 3.4375rem;
    height: 3.4375rem;
    display: grid;
    align-items: center;
    border-radius: 50%;
  }

  .controls .control.ringing i {
    font-size: 2.25rem;
    background-color: #ed6c02;
    width: 3.4375rem;
    height: 3.4375rem;
    display: grid;
    align-items: center;
    border-radius: 50%;
  }

  .log_chamadas {
    width: 100%;
  }

  .log_chamadas .chamada {
    display: flex;
    border-bottom: 0.1rem solid gray;
  }

  .log_chamadas .chamada .dados {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: center;
    padding: 0.9375rem;
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    color: #68696b;
    font-weight: bold;
  }

  .log_chamadas .chamada .dados span:first-child {
    color: black;
    font-size: 0.9375rem;
    font-weight: normal;
  }

  .log_chamadas .chamada .tipo {
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
  }

  .log_chamadas .chamada .tipo i {
    font-size: 1.40625rem;
    width: 2.8125rem;
    height: 2.8125rem;
    display: grid;
    align-items: center;
    border-radius: 50%;
  }

  .log_chamadas .chamada .tipo span {
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
  }

  .log_chamadas .chamada .tipo button {
    border: 0;
    background-color: transparent;
  }

  .log_chamadas .chamada .tipo button i {
    background-color: #00b37e;
  }

  .log_chamadas .chamada .tipo button i:hover {
    border: 1px solid black;
    border-radius: 50%;
    box-sizing: border-box;
    filter: brightness(120%);
  }

  .log_chamadas .chamada .tipo.recebida > i {
    background-color: #00b37e;
    color: #cfdff2;
  }

  .log_chamadas .chamada .tipo.discada > i {
    background-color: #00b37e;
    color: #d32f2f;
  }

  .log_chamadas .chamada .tipo.nao_atendida > i {
    background-color: #d32f2f;
  }
`;

export const KeyboardLine = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button:last-child {
    margin: 0;
  }
`;
