import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Accordion,
  Icon,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
} from 'semantic-ui-react'
import { format, parse } from 'date-fns'

const status = ['', 'Aberto', 'Fechado', 'Pendente']

export default function AccordionCloud({ historico }) {
  const [activeIndex, setActiveIndex] = useState(-1)

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex)
  }

  return (
    <Accordion fluid styled>
      <div key={historico[0].id}>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          <Icon name="dropdown" />
          Historico de Atendimento
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Table celled>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>ID</TableHeaderCell>
                <TableHeaderCell>Comentario</TableHeaderCell>
                <TableHeaderCell>Inicio</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Agente</TableHeaderCell>
              </TableRow>
            </TableHeader>

            <TableBody>
              {historico.length > 0 &&
                historico.map((item, index) => {
                  let dataInicial = ''

                  try {
                    dataInicial = format(
                      new Date(item.inicio),
                      'dd/MM/yyyy HH:mm:ss',
                    )
                  } catch (error) {
                    dataInicial = format(
                      parse(item.inicio, 'dd/MM/yyyy HH:mm:ss', new Date()),
                      'dd/MM/yyyy HH:mm:ss',
                    )
                  }

                  return (
                    <TableRow
                      key={item.id}
                      className={`
                      ${status[item.status] === 'Fechado' ? 'positive' : ''}
                      ${status[item.status] === 'Aberto' ? 'negative' : ''}
                    `}
                    >
                      <TableCell>
                        <Link target="_blank" to={`/chamado/${item.id}`}>
                          {item.id}
                        </Link>
                      </TableCell>
                      <TableCell>{item.comentario}</TableCell>
                      <TableCell>{dataInicial}</TableCell>
                      <TableCell>{status[item.status]}</TableCell>
                      <TableCell>{item.nome}</TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </Accordion.Content>
      </div>

      {/* {historico.map((item, index) => {
        return (
          <div key={item.id}>
            <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={handleClick}
            >
              <Icon name="dropdown" />
              {`#${item.id}`}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
              <p>{`${moment(item.inicio, 'DD/MM/YYYY HH:mm:ss').format(
                'DD-MM-YYYY HH:mm:ss',
              )} - ${status[item.status]} - ${item.nome}`}</p>
              <p>{item.comentario}</p>
            </Accordion.Content>
          </div>
        )
      })} */}
    </Accordion>
  )
}
